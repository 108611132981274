body {
  background-color: #add8e6;
}

.jumbotron {
  margin-top: 3em;
  background-color: #add8e6;
}

.card {
  margin-top: 3em;
}

h1 {
  text-align: center;
  padding-top: 1em;
}

.credits {
  text-align: center;
  opacity: 0.75;
}



.fullcredits {
  margin-top: 3em;
  margin-bottom: 3em;
}

p .fullcredits {
  opacity: 0.75;
}

h3 {
  opacity: 1;
  text-align: center;
}