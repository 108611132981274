.App {
  text-align: center;
  background-color: #add8e6;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #add8e6;
}

footer {
  color: black;
  font-size: 1em;
  margin-bottom: 3em;
  text-align: center;
}
